import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPage = () => (
    <Layout>
        <Seo title="Privacy Policy" />
        <div>
            <h2><strong>Privacy  Policy</strong></h2>
            <p><strong>(Last update December 23, 2018)</strong></p>
            <p>This page is used to inform regarding our privacy policy with the collection, use, and disclosure of Personal Information if anyone decided to use our Apps (“Application(s)”) and/or Website (“Website(s)”) created by MXT Games.</p>
            <h3><strong> 1. Information Collection, Use and General Data Protection Regulation (GDPR</strong>)</h3>
            <p>Our Privacy Policy reflects the General Data Protection Regulation (GDPR) standards. Our ads suppliers may use&nbsp;their software development kits (SDK) included in our applications&nbsp;to personalize ads for you.</p>
            <p>Link to privacy policies of  our partners used in application(s):</p>
            <ul><li>Admob: <a rel="noreferrer noopener" aria-label=" (opens in a new tab)" href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank">https://support.google.com/admob/answer/6128543?hl=en</a></li><li>Facebook: <a rel="noreferrer noopener" aria-label=" (opens in a new tab)" href="https://www.facebook.com/about/privacy/" target="_blank">https://www.facebook.com/about/privacy/</a></li><li>Firebase: <a rel="noreferrer noopener" aria-label=" (opens in a new tab)" href="https://firebase.google.com/support/privacy/" target="_blank">https://firebase.google.com/support/privacy/</a></li><li>Google Play Services: <a rel="noreferrer noopener" aria-label=" (opens in a new tab)" href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a></li><li>Unity: <a rel="noreferrer noopener" aria-label=" (opens in a new tab)" href="https://unity3d.com/ru/legal/privacy-policy" target="_blank">https://unity3d.com/ru/legal/privacy-policy</a></li></ul>
            <p> For a better experience, while using our Services, we may require you to provide us with certain personally identifiable information. The information that we request is will be retained by us and used as described in this privacy policy. </p>
            <h3><strong> 2. Log Data</strong></h3>
            <p> We want to inform you that whenever you use our Services, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Services, the time and date of your use of the Services, and other statistics. </p>
            <h3><strong>3. Cookies</strong></h3>
            <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your device internal memory. </p>
            <p> Our Services does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collection information and to improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Services/Apps. </p>
            <h3><strong>4. Opt-out rights</strong></h3>
            <p>You can always opt-out from settings. There are multiple opt-out options for users of this Application:</p>
            <p>On Apple you can opt-out this by choosing &#8220;limit ad tracking&#8221; option from privacy settings. On Google Android you can opt-out by choosing &#8220;Opt-out of interest based ads&#8221;.</p>
            <h3><strong> 5. Security</strong></h3>
            <p> We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. </p>
            <h3><strong> 6. Children’s Privacy</strong></h3>
            <p>Our Services/Apps do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please <a href="mailto:support@mxtgames.com">send us an email</a> so that we will be able to take necessary actions.</p>
            <h3><strong>7. Changes to This Privacy Policy</strong></h3>
            <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy. Changes are effective immediately after they are posted on this page. </p>
            <h3><strong>8.  Your Consent</strong></h3>
            <p>If you choose to use our Applications you accept our Privacy Policy. </p>
            <h3><strong> 9. Contact Us</strong></h3>
            <p> If you have any questions or suggestions about our Privacy Policy, feel free to <a href="mailto:support@mxtgames.com">contact us</a></p>
        </div>
        <Link to="/">Go Home</Link>
    </Layout>
)

export default PrivacyPage